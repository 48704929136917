import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: page not found" />
      <section className="text-center main">
          <div className="container">
              <h1>Whoops, we couldn't find that page.</h1>
              <div className="row no-gutters">
                  <div className="col-xs-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
                    <img className="img-fluid rounded img-small" src="/404.jpg" alt="Young child with their hands over their eyes" />
                  </div>
              </div>
          </div>
      </section>
  </Layout>
)

export default NotFoundPage
